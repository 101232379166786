import './App.css';
import { FcBinoculars, FcCommandLine } from '../node_modules/react-icons/fc';
import BarLoader from "react-spinners/BarLoader";


function App() {


  return (
    <div className="App">
      <header className="App-header">
        <p>
        
         <code>Digital Snow</code> <br></br><FcBinoculars /><FcCommandLine />
         
         
         <code>Coming soon</code>

        </p>

        <BarLoader
          color="#61dafb"
          cssOverride={{}}
          height={4}
          speedMultiplier={0.5}
          width={100}
        />

        <br></br>

        <a
          className="App-link"
          href="https://cryptoviews.io"
        >
          Check out Cryptoviews, our pretty Web3 wallet!
        </a>
      </header>
    </div>
    
    
  );
}

export default App;
